<app-headerhome class="header-sec"></app-headerhome>

<!-- ================================
    START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area gradient-bg-gray before-none">
    <div class="breadcrumb-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between">
                        <div class="section-heading text-left">
                            <h2 class="sec__title">Privacy Policy</h2>
                            <p class="sec__desc font-weight-regular pb-2">cranecoach</p>
                        </div>
                        <div class="btn-box bread-btns">
                            <a href="/EC_Data_Privacy_Policy_Full.pdf" class="theme-btn theme-btn-gray mr-2"><i class="la la-bookmark mr-1"></i>PDF</a>
                        </div>
                    </div><!-- end breadcrumb-content -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </div><!-- end breadcrumb-wrap -->
    <div class="bread-svg-box">
        <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
    END BREADCRUMB AREA
================================= -->

<!-- ================================
    START PRIVACY Policy AREA
================================= -->
<section class="job-details-area section--padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-10">
                <div class="form-box">
                    <div class="form-title-wrap">
                        <h3 class="title">1. Introduction</h3>
                    </div><!-- form-title-wrap -->
                    <div class="form-content contact-form-action">
                        <p class="pb-4">Easy Coach Ltd. values your privacy and this privacy statement outlines the personal
                            data collected by Easy Coach, how it is processed, and the purposes for which it is
                            collected. This statement should be read alongside the Terms and Conditions of Use
                            for other Easy Coach products and services, and in the event of any conflict, this
                            statement will take precedence. The statement applies to all visitors frequenting any
                            Easy Coach premises, suppliers, agents, merchants, dealers, and customers.</p>
                            
  
                        <h3 class="title pb-3">2. Definitions</h3>
                        <p class="pb-4">In this statement, "you" refers to customers who subscribe to, use, or purchase any
                            Easy Coach products or services, access Easy Coach websites, agents, dealers
                            and/or merchants who sign an agreement with Easy Coach, visitors to any Easy
                            Coach premises, and suppliers who have been contracted by Easy Coach and
                            executed a Supplier contract. "Easy Coach," "we" or "us," "our" and "ours" mean
                            Easy Coach Ltd. The word "includes" means that the following list is not exhaustive,
                            and therefore examples given are not the only things/situations included in the
                            meaning or explanation of that text.</p>
                        <h3 class="title pb-3">3. Collection of Information</h3>
                        <p class="pb-3">We collect personal information with your consent and knowledge when you register
                            for a specific product or service, buy, subscribe to, or use an Easy Coach product or
                            service, subscribe to Easy Coach or third-party premium rate services, Short
                            Message Service (SMS), email, or social media platforms, respond to or participate
                            in a survey, marketing promotion, prize competition, or special offer, visit, access or
                            use Easy Coach or third-party websites, interact with us as a supplier, agent,
                            merchant or dealer as prescribed in this statement, or visit any of our premises. We
                            may also collect your information from other organizations, including credit-reference
                            bureaus, fraud prevention agencies, and business directories.</p>
                        <p class="pb-3">We do not onboard minors, except where you additionally register on their behalf as
                            their parent and/or legal guardian. If you allow a child to use our services, you should
                            be aware that their personal information could be collected as described in this
                            statement.</p>
                        <p class="pb-3">Regarding the information we collect, the following is a non-exhaustive list of what
                            we may obtain and store about you:</p>
                        <p class="pb-1">3.1 Your personal identity information, including but not limited to your name,
                            address, phone number, identity document type and number, date of birth, email
                            address, age, and gender.</p>
                        <p class="pb-1">3.2 Details of your transactions with us when utilizing the MPESA service.</p>
                        <p class="pb-1">3.3 Your product and service preferences based on the information you have
                            provided or derived from your use of our or third-party network, products, and
                            services.</p>
                        <p class="pb-1">3.4 Personal details, such as your name, family information, age, and profiling
                            information (e.g., education level, bank account status, income bracket, etc.),
                            collected via surveys conducted by Easy Coach or our agents on our behalf.</p>
                        <p class="pb-1">3.5 Your communication with us, including but not limited to when you interact with
                            us through social media, conventional mail, email, or our chatbot. We may record
                            conversations, social media, or other interactions with us. Additionally, when you
                            visit an</p>
                        <p class="pb-1">3.6 Your call and message data records to us, such as phone numbers that you call
                            or send messages from (or receive calls and messages from), logs of calls,
                            messages, or data sessions on the Easy Coach platform and application, and
                            your approximate location.</p>
                        <p class="pb-1">3.7 We utilize CCTV surveillance recordings in strategic locations to ensure a safe
                            and secure environment for Easy Coach premises, as part of our commitment to
                            community safety, security, and crime prevention.</p>
                        <p class="pb-1">3.8 When you visit Easy Coach premises, we will collect and retain your personal
                            data, including your name, telephone number, and vehicle registration details.
                            We use this information to ensure effective visitor, contractor, and facilities
                            management, Health and Safety compliance (orderly entry and exiting to and
                            from the car parks and buildings), and inventory management.</p>
                        <p class="pb-1">3.9 We maintain a register of visitors in which we collect and keep your personal
                            data, such as names, company/institution details, telephone number, vehicle
                            registration details, and National ID number, for health, safety, and security
                            purposes.</p>
                        <p class="pb-1">3.10 When you use Easy Coach WIFI for guests and visitors, we collect email IDs
                            and provide username and password. We also record the device address and log
                            traffic information, including sites visited, duration, and date sent/received.</p>
                        <p class="pb-1">3.11 We may use your medical information to manage our services and products
                            for you, such as when you use our services designed for persons with disabilities.</p>
                        <p class="pb-1">3.12 We collect your personal information when you visit us for purposes of
                            accident and incident reporting. We will collect personal data from the injured
                            party or person suffering from ill health, such as name, address, age, next of kin,
                            and details of the incident to include any relevant medical history. This data is
                            collected as Easy Coach has a legal duty to document workplace
                            incidents/accidents and report certain types of accidents, injuries, and dangerous
                            occurrences arising out of its work activity to the relevant enforcing authority.</p>
                        <p class="pb-1">3.13 Incidents and accidents will be investigated to establish what lessons can be
                            learned to prevent such incidents/accidents from reoccurring, including the
                            introduction of additional safeguards, procedures, information, instruction, and
                            training or any combination of these. Monitoring may be undertaken on an
                            anonymized basis, and the information is also retained in the event of any claims
                            for damages.</p>
                        <p class="pb-1">3.14 Processing the products and services you have purchased from Easy Coach
                            or from third-party ecommerce platforms;</p>
                        <p class="pb-1">3.15 Billing you for our or third-party products and services, or deducting the
                            appropriate amount of credit from your account;</p>
                        <p class="pb-1">3.16 Addressing any inquiries or concerns you may have;</p>
                        <p class="pb-1">3.17 Verifying your identity information by accessing publicly available and/or
                            restricted government databases to comply with applicable regulatory
                            requirements;</p>
                        <p class="pb-1">3.18 Keeping you informed about new products and services, and sending you
                            offers or promotions based on how you use our or third-party products and services, unless you opt out of receiving marketing messages (you can contact
                            us at any time to opt out);</p>
                        <p class="pb-1">3.19 Complying with any legal, governmental, or regulatory requirement, or using
                            your information in connection with legal proceedings;</p>
                        <p class="pb-1">3.20 Conducting quality control, training, and ensuring effective systems
                            operations;</p>
                        <p class="pb-1">3.21 Protecting our data processing systems and services, including managing the
                            volume of calls, texts, and other use of our services;</p>
                        <p class="pb-1">3.22 Understanding how you use our products and services to develop or improve
                            them;</p>
                        <p class="pb-1">3.23 Preventing and detecting fraud or other crimes, and for debt recovery;</p>
                        <p class="pb-1">3.24 Using your information for research, statistical, survey, and other scientific or
                            business purposes;</p>
                        <p class="pb-1">3.25 Providing aggregated data (which does not contain any information that may
                            identify you as an individual) to third parties for research and scientific purposes;</p>
                        <p class="pb-1">3.26 Administering our online platforms/websites.</p>
                        <p class="pb-1">3.27 Depending on the types of products and services you use, we may process
                            various categories of personal data as defined in the Data Protection Act of
                            Kenya.</p>
                        <p class="pb-1">3.28 We will process your personal information based on any of the lawful bases
                            provided for under the Data Protection Law, including:</p>
                        <p class="pb-1">3.28.1 Performance of a product/service agreement with you;</p>
                        <p class="pb-1">3.28.2 Easy Coach's legitimate business interests;</p>
                        <p class="pb-1">3.28.3 Compliance with a mandatory legal obligation;</p>
                        <p class="pb-1">3.28.4 Your consent;</p>
                        <p class="pb-1">3.28.5 Public interest;</p>
                        <p class="pb-3">3.28.6 Your vital interest.</p>
                        <p class="pb-3">Our policy for retaining your personal information is based on fulfilling the purposes
                            for which it was collected. This includes meeting legal, regulatory, tax, accounting, or
                            reporting requirements. We may retain your personal data for a longer period in case
                            of a complaint or if we anticipate potential litigation related to our relationship with
                            you.</p>
                        <p class="pb-3">To determine the appropriate retention period, we consider the amount, nature, and
                            sensitivity of your personal data, the potential risks associated with unauthorized use
                            or disclosure of your data, the purpose for which we process your data, and whether
                            we can achieve those purposes through other means. We also consider compliance
                            with our internal policies and applicable legal, regulatory, tax, accounting, or other
                            requirements.</p>
                        <p class="pb-4">Any information that has been anonymized and can no longer be associated with
                            you may be kept indefinitely.</p>
                        
                            <h3 class="title pb-3">4. Disclosure of Information</h3>
                            <p class="pb-3">Easy Coach will disclose your information in compliance with applicable laws and
                                regulations. Each request for information will be assessed and reviewed, and we
                                may decline to grant such requests.</p>
                            <p class="pb-3">We may disclose your information to law enforcement agencies, regulatory
                                authorities, courts, or other statutory authorities in response to a demand issued with
                                the appropriate lawful mandate and where the form and scope of the demand is
                                compliant with the law. We may also share your information with our subsidiaries,
                                associates, partners, software developers, or agents who are involved in delivering
                                Easy Coach products and services you order or use.</p>
                            <p class="pb-3">In addition, we may disclose your information to fraud prevention and anti-money
                                laundering agencies, credit-reference agencies, publicly available and/or restricted
                                government databases to verify your identity information, debt-collection agencies or
                                other debt-recovery organizations, survey agencies that conduct surveys on behalf
                                of Easy Coach, emergency service providers where necessary for your rescue,
                                health and safety, and any other person or entity that we deem legitimately
                                necessary to share the data with.</p>
                            <p class="pb-3">If you send or intend to send mobile money, some of your information may be
                                passed on to any person involved in the operation of the mobile money service,
                                including Lipa Na M-PESA Merchants, mobile money interoperability partners, ATM
                                Switch providers, and vendors of the M-PESA money transfer technology platform.</p>
                            <p class="pb-3">We will not release any information to any individual or entity that is acting beyond its
                                legal mandate. Furthermore, we will obtain your express consent before sharing your
                                personal data with any third party for direct marketing purposes.</p>
                            
                            <h3 class="title pb-3">5. Marketing Messages</h3>
                            <p class="pb-3">To receive marketing messages from us, you may be required to opt in or give
                                explicit consent. You can adjust your marketing preferences at any time by writing to
                                us or following the opt-out links on any marketing message sent to you. You can also
                                contact us directly through the provided contacts.</p>
                            <p class="pb-3">If you opt-out of receiving marketing messages, this will not apply to personal data
                                provided to us as a result of a product, service already taken up, warranty
                                registration, product or service experience, or other transactions.</p>
                            <p class="pb-3">When you visit our websites, we may store some information on your computer using
                                "cookies" that enable us to recognise you during subsequent visits. The information
                                gathered is non-personal and includes the Internet Protocol (IP) address of your
                                computer, the date and time of your visit, which pages you browsed, and whether the
                                pages were delivered successfully.</p>
                            <p class="pb-3">We may use this data in aggregate form to develop customised services tailored to
                                your individual interests and needs. Depending on your browser, you may be
                                prompted before accepting cookies or be able to prevent your browser from
                                accepting any cookies at all. However, this may cause certain features of the website
                                to be inaccessible.</p>
                            <p class="pb-3">Our websites may provide hyperlinks to other websites on the Internet, which are
                                included to enhance your user experience and presented for information purposes
                                only. We do not endorse, recommend, approve, or guarantee any third-party
                                products and services by providing hyperlinks, and we are not affiliated with or
                                related to any third parties unless otherwise disclosed.</p>
                            <p class="pb-3">We are not responsible for the content or functionality of any externally linked
                                website or webpage. By clicking on a hyperlink, you will leave the Easy Coach
                                webpage and be subject to the terms of use, privacy, and cookie policies of the other
                                website that you choose to visit.</p>

                             <h3 class="title pb-3">6. Updating Your Information</h3>
                             <p class="pb-3">You can update your information and manage your account details by contacting us
                                in writing.</p>
                              <h3 class="title pb-3">7. Safeguarding and Protecting Your Information</h3>
                              <p class="pb-3">Easy Coach takes the protection of your personal information seriously. To ensure
                                confidentiality and integrity, we have implemented technical and operational
                                measures such as information classification, access control, cryptography, physical
                                and environmental security, as well as monitoring and compliance.</p>
                              <h3 class="title pb-3">8. International Data Transfers</h3>
                              <p class="pb-3">At times, we may need to transfer your personal information outside of Kenya. If we
                                do so, we will ensure that your information is properly protected in compliance with
                                the applicable Data Protection Laws.</p>
                              <h3 class="title pb-3">9. Your Rights</h3>
                              <p class="pb-3">You have various rights in relation to your personal data under Data Protection laws.
                                These include:</p>
                                <p class="pb-1">9.1 The right to be informed that we are collecting personal data about you</p>
                                <p class="pb-1">9.2 The right to access personal data that we hold about you and request information
                                    about how we process it</p>
                                <p class="pb-1">9.3 The right to request that we correct your personal data if it is inaccurate or
                                    incomplete</p>
                                <p class="pb-1">9.4 The right to request that we erase your personal data, subject to legal obligations
                                    or entitlements to retain it</p>
                                <p class="pb-1">9.5 The right to object or withdraw consent to the processing of your personal data,
                                    which we may continue if we have a legitimate or legal reason to do so</p>
                                <p class="pb-1">9.6 The right to request restricted processing of your personal data, which we may
                                    refuse if we are entitled or obligated to continue processing it</p>
                                <p class="pb-3">9.7 The right to request transfer of your personal data in an electronic format</p>
                                <p class="pb-3">If you wish to exercise any of these rights, please contact us in writing. We may need
                                    to verify your identity and request additional information to ensure that we do not
                                    disclose personal data to unauthorized persons. We will respond to legitimate
                                    requests within a reasonable time, but complex or multiple requests may take longer,
                                    in which case we will notify you and keep you updated.</p>
                                <h3 class="title pb-3">10. Right to File a Complaint</h3>
                                <p class="pb-3">If you believe that we have violated your rights under data protection laws, you have
                                    the right to file a complaint with the relevant supervisory authority responsible for
                                    personal data protection within the Republic of Kenya.</p>
                                <h3 class="title pb-3">11. Non-Compliance with this Policy</h3>
                                <p class="pb-3">We reserve the right to terminate any agreement with you or reject any request for
                                    information if you fail to comply with the provisions of this statement.</p>
                                <h3 class="title pb-3">12. Changes to this Policy</h3>
                                <p class="pb-3">We may update or modify this policy at any time. If we make any changes, you can
                                    always access the most recent version of the policy by clicking on this 
                                    <a href="/EC_Data_Privacy_Policy_Full.pdf">hyperlink</a>. Any changes to this policy will take effect from the date of
                                    notification on the Easy Coach website.</p>

                                <h3 class="title pb-3">13. How to Contact Us</h3>
                                <p class="pb-3">If you would like to contact us on any topics in this privacy policy, you can email us
                                    on <b>cranecoach9@gmail.com</b> or submit a request via our digital platforms.</p>
                                <p class="pb-3">As a Data Controller and Processor, below are the contact details of our Data
                                    Protection Officer:</p>
                                <p class="pb-3"><b>Data Protection Officer</b> </p>
                                <p class="pb-3"><b>Easy Coach Limited</b> </p>
                                <p class="pb-3"><b>Near Uchumi Railway</b> </p>
                                <p class="pb-3"><b>P.O Box 3185 - 00200 Nairobi Tel: +254 (020) 210711/2 cranecoachkenya.com</b> </p>
                                <p class="pb-3">Statement Effective Date</p>
                                <p class="pb-3">17-03-2023</p>


                    </div><!-- end form-content -->
                </div><!-- end form-box -->
            </div><!-- end col-lg-8 -->
        </div><!-- end row -->
    </div><!-- end container -->
</section><!-- end PRIVACY-POLICY-area -->
<!-- ================================
    END PRIVACY-POLICY AREA
================================= -->
<app-footer></app-footer>