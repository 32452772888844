    <!-- ================================
            START HEADER AREA
================================= -->
<header class="header-area ">
   
  <div class="header-top-bar padding-right-100px padding-left-100px">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="header-top-content">
            <div class="header-left">
              <ul class="list-items">
                <li>
                  <a href="tel:+254738200301"
                    ><i class="la la-phone mr-1"></i> 0769927171</a
                  >
                </li>
                <li>
                  <a href="#"
                    ><i class="la la-envelope mr-1"></i
                    >cranecoach9@gmail.com</a
                  >
                 
       
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="header-top-content">
            <div
              class="header-right d-flex align-items-center justify-content-end"
            >
             
              <div class="header-right-action">
                <a *ngIf="!isLoggedIn"
                  href="#"
                  class="theme-btn theme-btn-small theme-btn-transparent mr-1"
                  data-toggle="modal"
                  data-target="#signupPopupForm"
                  >Sign Up</a
                >
                <a *ngIf="!isLoggedIn"
                  href="#"
                  class="theme-btn theme-btn-small"
                  data-toggle="modal"
                  data-target="#loginPopupForm"
                  >Login</a
                >
                <div  *ngIf="isLoggedIn" class="dropdown">
                  <a href="#" class="dropdown-toggle" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="d-flex align-items-center">
                          <div class="avatar avatar-sm flex-shrink-0 mr-2"><img src="assets/images/user1.jpeg" alt="team-img"></div>
                         
                      </div>
                  </a>
                  <div class="dropdown-menu dropdown-reveal dropdown-menu-md dropdown-menu-right" style="">
                      <div class="dropdown-item drop-reveal-header user-reveal-header">
                          <h6 class="title text-uppercase">Welcome!</h6>
                      </div>
                      <div class="list-group drop-reveal-list user-drop-reveal-list">
                          <a [routerLink]="['/user-profile']" class="list-group-item list-group-item-action">
                              <div class="msg-body">
                                  <div class="msg-content">
                                      <h3 class="title"><i class="la la-user mr-2"></i>My Profile</h3>
                                  </div>
                              </div><!-- end msg-body -->
                          </a>
                          <a [routerLink]="['/my-booking']" class="list-group-item list-group-item-action">
                              <div class="msg-body">
                                  <div class="msg-content">
                                      <h3 class="title"><i class="la la-shopping-cart mr-2"></i>My Booking</h3>
                                  </div>
                              </div><!-- end msg-body -->
                          </a>
                         
                          <div class="section-block"></div>
                          <a  (click)="logoutUser()" class="list-group-item list-group-item-action">
                              <div class="msg-body">
                                  <div class="msg-content">
                                      <h3 class="title"><i class="la la-power-off mr-2"></i>Logout</h3>
                                  </div>
                              </div><!-- end msg-body -->
                          </a>
                      </div>
                  </div><!-- end dropdown-menu -->
              </div>

              </div>

            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  <div class="header-menu-wrapper padding-right-100px padding-left-100px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="menu-wrapper">
            <a href="#" class="down-button"
              ><i class="la la-angle-down"></i
            ></a>
            <div class="logo">
              <a href="index.html"
                ><img src="assets/images/crane.webp"  alt="logo"
              /></a>
              <div class="menu-toggler">
                <i class="la la-bars"></i>
                <i class="la la-times"></i>
              </div>
              <!-- end menu-toggler -->
            </div>
            <!-- end logo -->
            <div class="main-menu-content">
              <nav>
                <ul>
                  <li><a href="index.html">Home</a></li>
                  <li><a [routerLink]="['/contact-us']"  href="#">Contact us</a></li>
                  <li><a [routerLink]="['/about-us']"  href="#">About us</a></li>
                  <li><a href="https://www.facebook.com/cranecoachOfficialPage" target="_blank"><i class="lab la-facebook-f icon-color-facebook fa-1x "></i></a></li>
                  <li><a href="https://twitter.com/cranecoachltd_" target="_blank"><i class="lab la-twitter icon-color-twitter fa-1x"></i></a></li>
                  <!-- <li>
                    <a href="#">About us <i class="la la-angle-down"></i></a>
                    <ul class="dropdown-menu-item">
                      <li><a href="#">Booking offices</a></li>
                      <li><a href="#">About us</a></li>
                      
                    </ul>
                  </li> -->
                 
       
                </ul>
              </nav>
            </div>
         
          </div>
          <!-- end menu-wrapper -->
        </div>
        <!-- end col-lg-12 -->
      </div>
      <!-- end row -->

    </div>
    <!-- end container-fluid -->
  </div>

  <!-- end header-menu-wrapper -->
</header>

<!-- ================================
     END HEADER AREA
================================= -->