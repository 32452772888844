
<app-headerhome class="header-sec"></app-headerhome>

<section class="breadcrumb-area bread-bg-2">
  <div class="breadcrumb-wrap">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="breadcrumb-content text-center">
                      <div class="section-heading">
                          <h2 class="sec__title text-white">cranecoach COURIER TARRIFFS </h2>
                      </div>
                  </div><!-- end breadcrumb-content -->
              </div><!-- end col-lg-12 -->
          </div><!-- end row -->
      </div><!-- end container -->
  </div><!-- end breadcrumb-wrap -->
  <div class="bread-svg-box">
      <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
  </div><!-- end bread-svg -->
</section>

<section class="listing-form section--padding">
  <div class="container">
      <div class="row">
          <div class="col-lg-9 mx-auto">
              <div class="listing-header pb-4">
                  <h3 class="title font-size-28 pb-2">Letters and Parcels Courier Services</h3>
                  <p class="font-size-14">At our Letters and Parcels Courier Services Division, we courier Medium Sized Parcels, Letters and Documents to each of the over 30 branches where we have a presence. 
                    We take utmost care in ensuring that our customer’s letters and parcels reach the intended destinations in time, secure and in good condition. We package the letters and parcels in security 
                    tamper proof bags while on transit. Our delivery time for all letters and parcels is same day and/or within 24 hours.</p>
              </div>
              <div class="form-box">
                  <div class="form-title-wrap">
                      <h3 class="title text-center">1) Courier Charges Guide</h3>
                  </div><!-- form-title-wrap -->
                  <div class="form-content contact-form-action">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">SIZES</th>
                          <th scope="col">AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Small Envelopes</td>
                          <td>250.00</td>
                        </tr>
                        <tr>
                          <td>Big Envelopes with documents</td>
                          <td>300.00</td>
                        </tr>
                        <tr>
                          <td>Kampala Letters</td>
                          <td>400.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div><!-- end form-content -->
              </div><!-- end form-box -->
              <div class="form-box">
                <div class="form-title-wrap">
                    <h3 class="title text-center">2) PARCELS (SIZES. WEIGHT.VOLUME AMOUNT):</h3>
                </div><!-- form-title-wrap -->
                <div class="form-content contact-form-action">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">VOLUME</th>
                        <th scope="col">KSH. 200.00 - 500.00</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0-7 Kgs</td>
                        <td>300.00</td>
                      </tr>
                      <tr>
                        <td>8-10 Kgs</td>
                        <td>350.00</td>
                      </tr>
                      <tr>
                        <td>11-15 Kgs</td>
                        <td>450.00</td>
                      </tr>
                      <tr>
                        <td>16-19 Kgs</td>
                        <td>500.00</td>
                      </tr>
                      <tr>
                        <td>20-25 Kgs</td>
                        <td>600.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div><!-- end form-content -->
            </div><!-- end form-box -->
            <h3 class="title pb-3 text-center">All above prices are inclusive of V.A.T</h3>
            <h3 class="title pb-3 text-center _mb-10">26kgs and Above -Kshs 20/= @ Kilo plus V.A.T</h3>
            <h2>BY MANAGEMENT</h2>
            <p class="_mb-10">
              Our <b>Telephone Contacts</b> at the Letters and Parcels Courier Services Division are: 
              <b>+254 726 354 302, +254 738 200 156, +254 738 200 302, +254 726 354 303</b>
            </p>
            <b>Email: parcels@cranecoachkenya.com</b>
            <p>You can also get assistance from any of the contact numbers of the branch near your location.</p>
            <p>Read our <a href="EC_Terms_Conditions_Courier_Service.pdf"> TERMS AND CONDITION</a> </p>

          </div><!-- end col-lg-9 -->
      </div><!-- end row -->
  </div><!-- end container -->
</section>
<app-footer></app-footer>