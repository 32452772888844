<div class="about-wrapper">
  <app-headerhome class="header-sec"></app-headerhome>

    <!--start of about us section-->

<!-- ================================
  START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area bread-bg-9">
  <div class="breadcrumb-wrap">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="breadcrumb-content">
                      <div class="section-heading">
                          <h2 class="sec__title line-height-50 text-white">Crane Coach</h2>
                      </div>
                  </div><!-- end breadcrumb-content -->
              </div><!-- end col-lg-12 -->
          </div><!-- end row -->
      </div><!-- end container -->
  </div><!-- end breadcrumb-wrap -->
  <div class="bread-svg-box">
      <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
  </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
  END BREADCRUMB AREA
================================= -->



<!-- ================================
  START ABOUT AREA
================================= -->
<section class="about-area padding-bottom-90px overflow-hidden info-area padding-top-100px padding-bottom-70px">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="section-heading margin-bottom-40px">
                  <h2 class="sec__title">About Us</h2>
                  <h4 class="title font-size-16 line-height-26 pt-4 pb-2">
                     Crane Coach  </h4> 
                   <p class="sec__desc font-size-16 pb-3"> Welcome to Crane Coach Bus, your trusted partner in safe and comfortable transportation! 
                    Established in December 2019, we take immense pride in providing top-notch bus services to our valued passengers.</p>
                    <h4 class="title font-size-16 line-height-26 pt-4 pb-2"> Our Commitment:</h4>
                    <p class="sec__desc font-size-16 pb-3">At Crane Coach Bus, we believe that every journey counts. Whether it's a short trip across the city 
                        or a long-haul voyage to a distant destination, we pledge to make every mile memorable for our passengers. We prioritize safety above 
                        all else, and our team of experienced and skilled drivers undergo rigorous training to ensure smooth and secure travel.</p>
                  <h4 class="title font-size-16 line-height-26 pt-4 pb-2">Join Us on This Journey:</h4>
                  <p class="sec__desc font-size-16 pb-3">We extend our heartfelt gratitude to all our passengers who have placed their trust in us. Your support and encouragement motivate us to 
                    keep striving for excellence in all that we do. If you haven't traveled with Crane Coach Bus yet, we invite you to hop on board and experience the difference!</p>
                        <p class="sec__desc font-size-16 pb-3">Thank you for choosing Crane Coach Bus - where every ride is a memorable adventure!</p>
              </div><!-- end section-heading -->
          </div><!-- end col-lg-6 -->
          <div class="col-lg-5 ml-auto">
              <div class="image-box about-img-box">
                  <img src="assets/images/about/aboutCrane2.webp" alt="about-img" class="img__item img__item-1">
                  <img src="assets/images/about/aboutcrane1.webp" alt="about-img" class="img__item img__item-2">
              </div>
          </div><!-- end col-lg-5 -->
      </div><!-- end row -->
  </div><!-- end container -->
</section><!-- end about-area -->
<!-- ================================
  END ABOUT AREA
================================= -->



<app-footer></app-footer>

</div>


