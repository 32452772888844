import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {BookingService,HomeService, DataService } from '../services/index'
import {Results } from '../models/index'
import { Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate, LocationStrategy  } from '@angular/common';
import { Base64 } from 'js-base64';
import * as moment from 'moment';
import { EMPTY, fromEvent,  } from 'rxjs';
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;

  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  today=new Date();
  bsValue = new Date();
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);

  //traveldate:any;
  data = [];formdata={};
  formdataTravel={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  seatsSelected: any=[];
  seats =[];
  seatData:any =[];
  selectedBus:any;
  selectedBusStatus:boolean=false;
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any = [];
  return_schedule:any;
  resultsholder: any=[];
  seatMobile:any =[];
  isResultsLoading=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  travelDateInit: string;
  isUnavailabe=false;
  dataLoading=false;
  isReturnTicket=false;
  isShowingFilter=false;
  totalFare=0;
  returning=false;
  selectedIndex = -1;
  selectedDepatureId:string;
  selectedDestinationId:string;
  fromPlaceholder="From";
  searchDate:string;
  toPlaceholder="To";
  traveldate =new Date();
  allSeatArray=[];
  bookedSeatArray=[];
  booked_seat_session_id='';
  seatErrors=false;
  seatDataNew:any =[];
  seatRes:any;
  IsDiscount=false
  availableSeats=[];
  discountAmount:any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  currentBusId:any;
  submitted:boolean =false;
  isReturnTicketSelected=false;
  finalReturnDateInit:string;
  finalTravelDate: string;
  hide_from_ui_shuttle:number;
  selectedOptionPassengers;
  constructor(
    private render:Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeservice:HomeService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder
  ) {
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required],
      returndate:[]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required],
      returndate:[]
    });



   }

   returnClicked = () =>{
    this.returning=true;
  }

  closeReturn = () =>{
    this.returning=false;
  }

  showFilter () {
    this.isShowingFilter=true;
    console.log(this.isShowingFilter);
  }
  hideFilter() {
    this.isShowingFilter=false;
    console.log(this.isShowingFilter);
  }

  ngOnInit() {

    this.loadDestinations();
    this.dataService.currentMessage.subscribe(message =>{
      if(!message){
      message=localStorage.getItem('search-cranecoach');
      if(!message){
        this.router.navigate(['/'])
      }
    }

     localStorage.setItem('search-cranecoach', message);
      this.bookingdetails = JSON.parse(Base64.decode(message))
      this.traveldate = new Date(formatDate(this.bookingdetails.traveldate,"yyyy-MM-dd","en-KE"));
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;
      this.fromPlaceholder = this.bookingdetails.pickupname;
      this.toPlaceholder = this.bookingdetails.dropoffname;
      this.searchDate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
      this.checkDates();
      this.searchBuses(JSON.parse(Base64.decode(message)))
    });

  }

  checkDates=() =>{
    let traveldate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
    let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
    var date = new Date(traveldate);
    this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
    this.yesterday=new Date(date.setDate(date.getDate()+1));
    this.tomorrow =new Date(date.setDate(date.getDate()+2))
    this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
      if(traveldate == today)
          return true;
      else
        return false;
  }
  changeDate=(selected) =>{

    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.checkDates();
    let webformdata={
      'pickup' :  this.pickUpInit,
      'dropoff' : this.dropOffInit,
      'pickupname' : this.pickUpInit,
      'dropoffname' :this.dropOffInit,
      'traveldate' :this.travelDateInit,
      'returndate': '',
      'returning' : this.returning
    }

    this.bookingdetails =webformdata
 
    let message=Base64.encode(JSON.stringify(webformdata));
    this.dataService.changeMessage(message);
    localStorage.setItem('search-cranecoach', message);

    this.searchBuses(webformdata);
  }

  interChange=()=>{
    this.changeI=!this.changeI;
    if(this.changeI){

      this.pickUpInit=this.bookingdetails.dropoffname;
      this.dropOffInit=this.bookingdetails.pickupname;
      this.travelDateInit=this.bookingdetails.traveldate;

       const dropOff = this.data.find(city => city.name === this.pickUpInit);
       const pickUp = this.data.find(city => city.name === this.dropOffInit);

      let webformdata={
        'pickup' : dropOff.id,
        'dropoff' : pickUp.id,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
        'returndate': '',
        'returning' : this.returning
      }
      this.bookingdetails =webformdata
      let message=Base64.encode(JSON.stringify(webformdata));
      this.dataService.changeMessage(message);
      localStorage.setItem('search-cranecoach', message);
      this.searchBuses(webformdata);
    }else{
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;

       const dropOff = this.data.find(city => city.name === this.bookingdetails.dropoffname);
       const pickUp = this.data.find(city => city.name === this.bookingdetails.pickupname);

      let webformdata={
        'pickup' : pickUp.id,
        'dropoff' : dropOff.id,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
        'returndate': '',
        'returning' : this.returning
      }
      this.bookingdetails =webformdata

      let message=Base64.encode(JSON.stringify(webformdata));
      this.dataService.changeMessage(message);
      localStorage.setItem('search-cranecoach', message);

      this.searchBuses(webformdata);

    }
  }

  private loadDestinations= () => {
    let storedCities = localStorage.getItem('cities');
    if(storedCities){
      this.data = JSON.parse(Base64.decode(storedCities))
      console.log(this.data);
    }else{
     this.router.navigate(['/'])
    }

  }

  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
  }

  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }

  submitWebForm(reactiveForm) {
    this.submitted = true;
    let value = reactiveForm;
    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;

    let webformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' :value.dropoff,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }
    this.bookingdetails =webformdata
    let message=Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search-cranecoach', message);
    this.dataService.changeMessage(message);

    this.searchBuses(webformdata);
  }

  submitMobileForm() {
    this.submitted = true;
     let value = this.reactiveMobileForm.value;

    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;


    let mobileformdata={
      'pickup' : value.pickup.id,
      'dropoff' : value.dropoff.id,
      'pickupname' : value.pickup.name,
      'dropoffname' : value.dropoff.name,
      'traveldate' : traveldate,
    }

    this.bookingdetails =mobileformdata
    let message=Base64.encode(JSON.stringify(mobileformdata));

    localStorage.setItem('search-cranecoach', message);

    this.searchBuses(mobileformdata);
  }

  toggleShow(results: any) {
    this.seatsSelected=[];
    this.totalFare=0;
      results.hide_from_ui = ! results.hide_from_ui;
    }

    // selectSeats(results: any) {

    //   this.selectedBus=results;
    //   this.selectedBusStatus=true;
    //   this.dataLoading =true;
    //   this.seatsSelected=[];
    //   this.seatData = [];
    //   this.seatMobile=[];
    //   this.totalFare=0;
    //   this.seats=[];
    //   let traveldate=formatDate(this.travelDateInit,"d-M-yyyy","en-KE");
    //   let query ={
    //     'fleet_registration_id': results.id,
    //     'date': traveldate,
    //     'start_point': results.origin_city_id,
    //     'end_point': results.destination_city_id,
    //     'alias': results.operator.alias,
    //     'rsc_id': results.route_schedule_id,
    //     'bus_id': results.id,
    //     'id': results.id,
    //     'no_of_seats': results.number_of_available_seats,
    //     'fare': results.fare,
    //     'leaving_from': results.from,
    //     'going_to': results.to,
    //   };
    //     this.getAvailableSeats(query);
    //   }

    selectSeats(results: any) {
      console.log(results)
      this.hide_from_ui_shuttle = results.trip_id;
      const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
        let seatsPrices =results.seats_types;
        let seatPrice ='0';
        for(let i=0; i< seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
        }
       
        this.totalFare=parseInt(seatPrice);
   

      this.seatsSelected=[];
      this.totalFare=0;
      results.hide_from_ui = true;

      }

      selectPassOption(id: number,selectedBus) {
        console.log(id)
        let seatsPrices =selectedBus.seats_types;
        let seatPrice ='0';
        for(let i=0; i< seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
       }
       console.log(id)
       console.log(seatPrice)
         //getted from event
    
         this.seatsSelected=[];
         this.selectedOptionPassengers=id
         this.totalFare= id * parseInt(seatPrice)
    
         for(let i=0;i<this.selectedOptionPassengers;i++)
         {
             const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
             
             this.seatsSelected.push({id:random(1, 60),space_type:'business'});
         }
         console.log(this.seatsSelected)
         console.log(this.totalFare)
    
       }

      getAvailableSeats(query){
        this.seatErrors=false;
        this.service.getSeats(query).subscribe(
        response => {
          this.seatErrors=false;
          this.seatRes = response;
          this.availableSeats =this.seatRes.data.available_seats.available_seat_ids;
          this.seats = this.seatRes.data.available_seats.seats;
          this.booked_seat_session_id =this.seatRes.data.available_seats.booked_seat_session_id;
      
          for(let i=0;i<this.seats.length;i++)
          {
            let iseats =this.seats[i];
            let s =5;
            for(let j=0;j< iseats.length;j++){
                var temp = {s:s,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type};
                this.seatData.push(temp);
                s--;
            }
            let m=5;
            for(let j=0;j< iseats.length;j++){
              var mobiletemp = {m:m,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type};
              this.seatMobile.push(mobiletemp);
              m++;
           }
              this.seatData.sort(function (a, b) {
                return a.s - b.s;
              });
              this.seatMobile.sort(function (a, b) {
                return a.m - b.m;
              });
              this.dataLoading=false;


          }
          const groupBy = key => array =>
          array.reduce((objectsByKeyValue:any, obj:any) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});

          const groupByVal = groupBy('s');
          const groupByValMobile = groupBy('row');
          this.seatData = Object.values(groupByVal(this.seatData));
          this.seatMobile = Object.values(groupByValMobile(this.seatMobile));

        },
        error => {
          this.seatErrors=true;
          this.dataLoading =false;

          return EMPTY;

        }
      );
      }




      seatSelect(event:any,result:any,id:number,space_type:string){
        let seatsPrices =result.seats_types;
        let seatPrice ='0';

            for(let i=0; i<seatsPrices.length; i++){
              if(space_type == seatsPrices[i].alias || (space_type =="business" &&  seatsPrices[i].alias =="business")){
                if(seatsPrices[i].fare > 0){
                  seatPrice=seatsPrices[i].fare;
                }
    
              }else{
                if(seatsPrices[i].fare > 0){
                  seatPrice=seatsPrices[i].fare;
                }
              }
            }
        
       if(event.srcElement.parentElement.classList.contains('unavailable')){
       }else{
         if(this.seatsSelected.some(e => e.id === id)){
 
           if(event.srcElement.classList.contains('single-seat')){
             event.srcElement.classList.remove("selected");
           }
           this.totalFare=this.totalFare-parseInt(seatPrice);
           this.seatsSelected = this.seatsSelected.filter(item => item.id !== id);
           event.srcElement.parentElement.classList.remove('selected');
         }else{
           if(event.srcElement.classList.contains('single-seat')){
             event.srcElement.classList.add("selected");
           }
           this.totalFare=this.totalFare+parseInt(seatPrice);
           this.seatsSelected.push({id:id,space_type:space_type});
           event.srcElement.parentElement.classList.add('selected');
           if(this.return_schedule){
             this.busSelected=result;
             this.currentBusId=result.id;
             this.busOperatorSelected=result.operator.alias;
             this.isBusSelected =true;
           }
         }
       }
 
      }



     refreashSeats(){
      this.dataLoading=false;

      this.service.onSearch(this.bookingdetails).subscribe(
        response => {
          this.dataLoading=false;
          this.res = response;
          this.schedule =  this.results.schedule;
          this.routename =  this.results.route;
          this.pickup =  this.results.pickup;
          this.dropoff =  this.results.dropoff;
          this.fleet =  this.results.fleet;
          this.seat_price =  this.results.seat_price;
        },
        error => {
          console.log('oops', error);
        }
      );

    }

    submitForm(result) {

      this.submitted = false;
      if (result.dropoff_points.length <1 ) result.dropoff_points.push(this.dropOffInit);
      if (result.boarding_points.length <1 ) result.boarding_points.push(this.pickUpInit);
      if(this.isReturnTicket && !this.isReturnTicketSelected){
        this.isReturnTicketSelected=true;
        let resultsData:any;
        let resultSeats:any;
        resultsData=result;
      
        resultSeats=this.seatsSelected;
        // var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
        if(resultsData.operator.alias =='cranecoach'){
          var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
          resultsData.departure_date = momentObj.format('DD/MM/YYYY'); // 2016-07-15
        }else if (resultsData.is_shuttle){
          var momentObj = moment(resultsData.departure_date, 'YYYY-MM-DD');
          resultsData.departure_date = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        }
        else{
          resultsData.departure_date = this.finalReturnDateInit;
        }
        this.formdataReturn={
          'result':resultsData,
          'fare': resultsData.fare,
          'title': resultsData.from + ' to ' + resultsData.to,
          'total_fare':this.totalFare,
          "search_details": this.bookingdetails,
          'search_from' : this.fromPlaceholder,
          'search_to' :this.toPlaceholder,
          'pickup' : resultsData.boarding_points.length,
          'dropoff' :resultsData.dropoff_points,
          'totalfare' : this.totalFare,
          'seatsSelected' : resultSeats,
          'booking_session_id':this.booked_seat_session_id
        }
      }else{
        let initialData:any;
        initialData=result;
  

        //initialData.departure_date = this.finalTravelDate;
        if(initialData.operator.alias =='cranecoach'){
          var momentObj = moment(initialData.departure_date, 'MM-DD-YYYY');
          initialData.departure_date = momentObj.format('MM/DD/YYYY'); // 2016-07-15
        }else if (initialData.is_shuttle){
          var momentObj = moment(initialData.departure_date, 'YYYY-MM-DD');
          initialData.departure_date = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        }else{
          initialData.departure_date = this.finalTravelDate;
        }
        this.formdataTravel={
          'result':initialData,
          'fare': initialData.fare,
          'title': initialData.from + ' to ' + initialData.to,
          'total_fare':this.totalFare,
          "search_details": this.bookingdetails,
          'pickup' : initialData.boarding_points.length,
          'dropoff' :initialData.dropoff_points,
          'search_from' : this.fromPlaceholder,
          'search_to' :this.toPlaceholder,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'booking_session_id':this.booked_seat_session_id
        }
      }
      //check if the trip is one way
      if(this.bookingdetails.returning && !this.isReturnTicket){
        this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
        let returnsearch={
          'pickup' : this.bookingdetails.dropoff,
          'dropoff' : this.bookingdetails.pickup,
          'pickupname' : this.bookingdetails.dropoff,
          'dropoffname' :this.bookingdetails.pickup,
          'traveldate' :this.bookingdetails.returndate,
          'returndate' :'',
        }
        this.isReturnTicket=true;
        this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
        this.searchBuses(returnsearch);
      } else if(this.bookingdetails.returning && this.isReturnTicket){
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
     
        let payreturn=Base64.encode(JSON.stringify(this.formdataReturn));
        let bookingdetails=Base64.encode(JSON.stringify( this.bookingdetails));
        let syncData={
            'travel':paytravel,
            'return':payreturn,
            'bookingdetails':bookingdetails,
            'returning':true
        }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changeMessage(pay);
        localStorage.setItem('payments-cranecoach',pay)
        this.router.navigate(['/payments', paymessage])
      } else {
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let bookingdetails=Base64.encode(JSON.stringify(this.bookingdetails));
        this.bookingdetails
          let syncData={
            'travel':paytravel,
            'return':'',
            'bookingdetails':bookingdetails,
            'returning':false
          }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changeMessage(pay);
        localStorage.setItem('payments-cranecoach',pay)
        this.router.navigate(['/payments', paymessage])
      }

      
    }

  searchBuses(search){
    this.isResultsLoading=true;
    this.destinationLoading=true;
    this.travelDateInit=search.traveldate;
    this.pickUpInit = search.pickupname;
    this.dropOffInit = search.dropoffname;
    this.pickup =   search.pickup;
    this.dropoff =  search.dropoff;
    this.interChangeValues(search.pickupname,search.dropoffname);
    this.finalTravelDate =search.traveldate;
    this.finalReturnDateInit =search.returndate
    this.service.onSearch(search).subscribe(
      response => {
        this.isResultsLoading=false;
        this.destinationLoading=false;
        this.res = response;

        if(this.returning){
          let newResults=[];
          let resultsData = this.res.data.initial_trip;
          for(let i=0;i < resultsData.length;i++)
          {
              if( resultsData[i].operator.alias != 'cranecoach-none'){
                newResults.push(resultsData[i]);
              }
          }
          this.results=newResults;
          this.return_schedule = this.res.data.return_trip;
        }else{
          let newResults=[];
          let resultsData = this.res.data.schedule;
          for(let i=0;i < resultsData.length;i++)
          {
              if( resultsData[i].operator.alias != 'cranecoach-none'){
                newResults.push(resultsData[i]);
              }
          }
          this.results=newResults;
        }
        if( this.res.data ) {
          this.pickup =  this.pickUpInit;
          this.dropoff =  this.dropOffInit;
        }

        console.log(this.results);
        this.resultsholder = this.results;

      },
      error => {
        return EMPTY;
      }
    );

  }


  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    // do something when input is focused
  }


}
