import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {Destinations } from '../models/index'
import {HomeService,DataService } from '../services/index'
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router, NavigationEnd} from "@angular/router"
import { Base64 } from 'js-base64';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  bsInlineValue = new Date();
  reactiveForm: UntypedFormGroup;
  returnForm: UntypedFormGroup;
  destinationLoading=false;
  pickup='';
  dropoff='';
  From ="From";
  To ="To";
  traveldate='';
  destinations: Destinations[] = [];
  destination: any = {};
  res: any = {};
  today=new Date();
  bsValue = new Date();
  public pickplaceholder: string = 'Nairobi';
  public destplaceholder: string = 'Kisumu';
  public historyHeading: string = 'Recently selected';
  keyword = 'name';
  isClicked=false;
  isPickupClicked=false;
  returning=false;
  //traveldate:any;
  data = [];
  msg = '';
  formdata={};
  submitted:boolean = false;
  submittedReturn:boolean = false;
  citires:any;
  loading: boolean;
  form = new UntypedFormGroup({
    traveldate: new UntypedFormControl(new Date()),
  });

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [ '<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>' ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      }
    },
    nav: true
  }
  constructor(
    private service : HomeService,
    private dataService : DataService,
    private router:Router,
    private _fb: UntypedFormBuilder
        ) {
          this.reactiveForm = _fb.group({
            pickup:['', Validators.required],
            dropoff:['', Validators.required],
            traveldate:[new Date(), Validators.required],
            // returndate:['']
          });

          this.returnForm = _fb.group({
            pickupReturn:['', Validators.required],
            dropoffReturn:['', Validators.required],
            traveldateReturn:[new Date(), Validators.required],
            returndate:[new Date(), Validators.required]
          });
        
    }
  ngOnInit() {
    this.loadDestinations();
  }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    this.isClicked=true;
  }

  returnClicked =() =>{
    this.returning=true;
  }

  closeReturn(){
    this.returning=false;
  }

  onClosed(e){
    this.isClicked=false;
  }

  onPickupFocused(e){
    this.isClicked=true;
    this.isPickupClicked=true;

  }
  onPickupClosed(e){
    this.isClicked=false;
    this.isPickupClicked=false;
  }





clickTopCity(depature,destination){
  var traveldate = formatDate(this.today,"yyyy-MM-dd","en-KE");
  let kisumu={
      'pickup' : depature,
      'dropoff' : destination,
      'pickupname' :depature,
      'dropoffname' : destination,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }
    let p=Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
}

clickOffer(depature,destination,date){

  console.log(depature,destination)
  var traveldate = formatDate(date,"yyyy-MM-dd","en-KE");
  let kisumu={
      'pickup' : depature,
      'dropoff' : destination,
      'pickupname' :depature,
      'dropoffname' : destination,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }

    let p=Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

}


  submitForm() {
    this.submitted = true;
     let value = this.reactiveForm.value;
    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;
    var returndate='';
    if(this.returning){
      var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
       value.returndate=returndate;
    }

    this.formdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : this.returning
    }


    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
  }

  submitReturnForm() {
    this.submittedReturn = true;
     let value = this.returnForm.value;
    var traveldate = formatDate(value.traveldateReturn,"yyyy-MM-dd","en-KE");
    value.traveldateReturn=traveldate;
    var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
    value.returndate=returndate;
    this.formdata={
      'pickup' : value.pickupReturn,
      'dropoff' : value.dropoffReturn,
      'pickupname' : value.pickup,
      'dropoffname' : value.dropoff,
      'traveldate' : traveldate,
      'returndate': returndate,
      'returning' : true
    }


    let p=Base64.encode(JSON.stringify(this.formdata));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])
  }

  private loadDestinations() {

    this.destinationLoading=true;

    this.service.getDestinations().subscribe(
      response => {
        let list: string[] = [];
        this.res = response;
          this.res.data.all_cities.forEach(element => {
              list.push(element.name);
          });
        this.data=list;
        let cities=Base64.encode(JSON.stringify(this.res.data.all_cities));
        localStorage.setItem('cities', cities);
        this.destinationLoading=false;
      },
      error => {

      }
    );

  }

}
